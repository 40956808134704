import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormState } from 'react-hook-form';

import BootstrapModal from 'react-bootstrap/Modal';
import BModalHeader from 'react-bootstrap/ModalHeader';

import type { FormProps } from '@acadeum/core-ui';
import { CaretLeftIcon } from '@acadeum/icons';

import useScreenSize from '../../utils/useScreenSize';

import type { TagProps } from '../Tag';
import { Tag } from '../Tag';
import { CloseButton } from '../CloseButton';
import { Form } from '../Form';
import { Text } from '../Text';
import type { ActionsProps } from '../Actions';
import { Actions } from '../Actions';
import { HStack, VStack } from '../Stack';
import { Button } from '../Button';

import { ModalBody } from './ModalBody';
import { ModalFooter } from './ModalFooter';

import {
  ModalSelectComboboxContextProvider,
  ModalLexicalEditorContextProvider,
  ModalDatePickerContextProvider,
  ModalUIContextProvider
} from './context/provider';
import { useModalSelect, useModalUI, useModalLexicalEditorContext } from './context/hooks';

import styles from './Modal.module.scss';

export interface ModalProps extends Partial<Pick<FormProps, 'showPendingToast' | 'children' | 'isNestedForm' | 'defaultValues'>> {
  show?: boolean;
  onHide: (show: false) => void;
  noHeader?: boolean;
  fullscreen?: boolean;
  centered?: boolean;
  closeLabel?: string;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  status?: TagProps['variant'];
  backdrop?: 'static';
  size?: 'small' | 'narrow' | 'wide' | 'medium';
  onSubmit?: FormProps['onSubmit'];
  actions?: ActionsProps['actions'];
  onBack?: (show: false) => void;
}

const ModalComponent: React.FC<ModalProps> = ({
  show,
  onHide: propsOnHide,
  noHeader,
  title,
  subtitle,
  status,
  closeLabel,
  children,
  fullscreen,
  size,
  onSubmit,
  centered,
  showPendingToast,
  backdrop,
  isNestedForm,
  defaultValues,
  actions,
  onBack
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isSmallScreen } = useScreenSize();

  const modalRef = useRef();

  const modalSelectComboboxContext = useModalSelect();
  const modalLexicalEditorContext = useModalLexicalEditorContext();

  const enforceFocus = modalSelectComboboxContext?.enforceFocus && modalLexicalEditorContext?.enforceFocus;

  const onHide = () => propsOnHide?.(false);

  const modalHeader = noHeader ? null : (
    <ModalHeader
      onBack={onBack}
      title={title as string}
      subtitle={subtitle as string}
      status={status}
      onHide={onHide}
      closeLabel={closeLabel}
      size={size}
      actions={actions}
      loading={isSubmitting}
    />
  );

  return (
    <BootstrapModal
      enforceFocus={enforceFocus}
      show={show}
      onHide={onHide}
      fullscreen={(isSmallScreen || fullscreen) ? true : undefined}
      ref={modalRef}
      backdropClassName={classNames(styles.ModalBackdrop, {
        [styles['ModalBackdrop--loading']]: isSubmitting
      })}
      contentClassName={styles.ModalContent}
      dialogClassName={classNames(styles.ModalDialog, {
        [styles[`ModalDialog--${size}`]]: size
      })}
      backdrop={isSubmitting ? 'static' : backdrop}
      centered={centered}
    >
      <ModalUIContextProvider>
        {typeof onSubmit === 'function' ? (
          <Form
            modal
            className={classNames('modal-content', styles.ModalForm)}
            onSubmit={onSubmit}
            showPendingToast={showPendingToast}
            isNestedForm={isNestedForm}
            defaultValues={defaultValues}
          >
            {(childrenParameters) => {
              return (
                <>
                  {modalHeader}
                  {typeof children === 'function' ? children(childrenParameters) : children}
                  <UpFormState
                    setIsSubmitting={setIsSubmitting}
                  />
                </>
              );
            }}
          </Form>
        ) : (
          <>
            {modalHeader}
            {children}
          </>
        )}
      </ModalUIContextProvider>
    </BootstrapModal>
  );
};

function UpFormState({ setIsSubmitting }) {
  const formState = useFormState();

  useEffect(() => {
    if (formState) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState, formState.isSubmitting]);

  return null;
}

UpFormState.propTypes = {
  setIsSubmitting: PropTypes.func.isRequired
};

function ModalHeader({
  title,
  subtitle,
  status,
  closeLabel,
  onHide,
  loading,
  size,
  actions,
  onBack
}) {
  const { hasScroll, setHasHeader } = useModalUI();

  useEffect(() => {
    setHasHeader(true);
    return () => setHasHeader(false);
  }, []);

  return (
    <BModalHeader className={classNames(styles.ModalHeader, {
      [styles['ModalHeader--modalHasScroll']]: hasScroll,
      [styles['ModalHeader--paddingBottomSmall']]: size === 'narrow'
    })}>
      <VStack style={{ width: '100%' }} align="stretch">
        <HStack align="center" gap="sm">
          <HStack align="center" gap="sm" style={{ flex: '1' }}>
            {onBack && (
              <Button
                onClick={onBack}
                variant="text"
                icon={CaretLeftIcon}
              />
            )}
            {typeof title !== 'string' ? title : (
              <Text variant="headingMd" as="h2">
                {title}
              </Text>
            )}
            {status && (
              <Tag variant={status}/>
            )}
          </HStack>
          <HStack justify="between" align="center" gap="sm" style={{ alignSelf: 'flex-start' }}>
            {actions && (
              <Actions
                variant="kebab"
                actions={actions}
              />
            )}
            <CloseButton disabled={loading} onClick={onHide}>
              {closeLabel}
            </CloseButton>
          </HStack>
        </HStack>
        {typeof subtitle !== 'string' ? subtitle : (
          <Text mt="sm">
            {subtitle}
          </Text>
        )}
      </VStack>
    </BModalHeader>
  );
}

export async function waitForModalToClose() {
  return new Promise((resolve) => setTimeout(resolve, 150));
}

ModalHeader.propTypes = {
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node,
  onHide: PropTypes.func.isRequired,
  closeLabel: PropTypes.string,
  loading: PropTypes.bool
};

export const Modal: React.FC<ModalProps> & {
  Body: typeof ModalBody,
  Footer: typeof ModalFooter,
} = (props) => {
  return (
    <ModalLexicalEditorContextProvider>
      <ModalDatePickerContextProvider>
        <ModalSelectComboboxContextProvider>
          <ModalComponent {...props} />
        </ModalSelectComboboxContextProvider>
      </ModalDatePickerContextProvider>
    </ModalLexicalEditorContextProvider>
  );
};

Modal.propTypes = {
  closeLabel: PropTypes.string,
  size: PropTypes.oneOf(['small', 'narrow', 'wide', 'medium']),
  noHeader: PropTypes.bool,
  show: PropTypes.bool,
  fullscreen: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ]).isRequired,
  centered: PropTypes.bool,
  showPendingToast: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.func.isRequired
  ]),
  title: PropTypes.node,
  subtitle: PropTypes.node,
  onHide: PropTypes.func.isRequired
};

Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

