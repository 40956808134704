import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import get from 'lodash/get';

import { FEE_TYPE_OPTIONS, INVOICE_STATUS_OPTIONS, optionsWithAllOption } from '@acadeum/selection-options';
import { useTranslate } from '@acadeum/translate';
import type { TableExportDataColumns } from '@acadeum/ui';
import { Actions, DataBlock, Filters, FormatDate, Link, Page, Table, TabsNavigation, Tag } from '@acadeum/ui';
import type {
  FetchTransfersInput,
  FetchTransfersOutputListItem,
  UseFetchInvoiceQuery,
  UseFetchTransfersQuery,
  UseLazyFetchTransfersQuery
} from '@acadeum/api';
import { useQueryWithPagination } from '@acadeum/hooks';

import { useSettingsRoutes } from '../../hooks/useSettingsRoutes';
import { useApp } from '../../providers/useApp';
import { useOnError } from '../../providers/useOnError';
import { useLocation } from '../../providers/useLocation';
import { formatCurrency } from '../../helpers/format';

import { InvoiceDetails, UnableDownloadModal } from './ui/InvoiceDetails';

const columnHelper = createColumnHelper<FetchTransfersOutputListItem>();

const feeTypesOptions = optionsWithAllOption(FEE_TYPE_OPTIONS);
const invoiceStatusOptions = optionsWithAllOption(INVOICE_STATUS_OPTIONS);

export interface TeachingInvoicesPageProps {
  useFetchTransfersQuery: UseFetchTransfersQuery;
  useLazyFetchTransfersQuery: UseLazyFetchTransfersQuery;
  useFetchInvoiceQuery: UseFetchInvoiceQuery;
}

export const TeachingInvoicesPage: FC<TeachingInvoicesPageProps> = ({
  useFetchTransfersQuery,
  useFetchInvoiceQuery,
  useLazyFetchTransfersQuery
}) => {
  const t = useTranslate('shared-admin-ui.financial');
  const { getSettingsUrl } = useSettingsRoutes();
  const { app } = useApp();
  const onError = useOnError();

  const location = useLocation();

  const { getTeachingInvoicesUrl, getInvoiceHistoryUrl } = useSettingsRoutes();

  const { columns, exportDataColumns } = useColumns(useFetchInvoiceQuery);

  const fetchDataForExport = useFetchDataForExport(useLazyFetchTransfersQuery);

  const {
    data,
    error,
    isFetching,
    _globalFilterOptions,
    _paginationOptions,
    _filtersOptions
  } = useQueryWithPagination(useFetchTransfersQuery, {
    search: location.query.search as string,
    filters: {
      invoiceExists: true
    }
  });

  if (error) {
    return onError(error);
  }

  return (
    <Page
      title={t('invoices')}
      breadcrumbs={[[t(app === 'admin' ? 'general' : 'settings', { global: true }), getSettingsUrl()], t('invoices')]}
    >
      <TabsNavigation
        menuItems={[
          {
            title: t('enrollingInvoices'),
            url: getInvoiceHistoryUrl()
          },
          {
            title: t('teachingInvoices'),
            url: getTeachingInvoicesUrl(),
            active: true
          }
        ]}
      />

      <Filters
        border
        values={_filtersOptions.filters}
        onFiltersChange={(newValue) => {
          // @ts-expect-error
          if (newValue.type === 'ANNUAL_FEE') {
            // @ts-expect-error
            delete newValue.institutionIds;
          }
          // @ts-expect-error
          if (newValue?.institutionIds?.length === 0) {
            // @ts-expect-error
            delete newValue?.institutionIds;
          }
          _filtersOptions.onFiltersChange({
            ...newValue,
            invoiceExists: true
          });
        }}
      >
        <Filters.Row>
          <Filters.Select
            name="type"
            label={t('type')}
            getFilterLabel={(value) => t(`paymentTypes.${value}`)}
            options={feeTypesOptions}
          />
          {_filtersOptions.filters.type !== 'ANNUAL_FEE' && (
            <Filters.Institution
              multiple
              type="institution"
              name="institutionIds"
              label="Counterparty"
            />
          )}
          <Filters.Select
            name="invoiceStatus"
            label="Status"
            options={invoiceStatusOptions}
          />
          <Filters.DateRange
            utc
            name="invoiceCreatedAt"
            label="Invoice Date"
          />
        </Filters.Row>
      </Filters>

      <Table
        enableSorting
        enableRowSelection
        columns={columns}
        data={data?.results}
        loading={isFetching}
        globalFilterOptions={_globalFilterOptions}
        paginationOptions={_paginationOptions}
        translate={{
          resultText: ({ totalCount }) => t('invoicePage.resultText', { totalCount }),
          selectedResultText: ({ totalCount, selectedRowsCount }) => t('invoicePage.selectedResultText', {
            totalCount,
            selectedRowsCount
          }),
          searchPlaceholder: t('invoicePage.searchByInvoiceId')
        }}
        exportOptions={{
          type: 'xlsx',
          fileName: 'teaching_invoices',
          exportDataColumns,
          fetchDataForExport
        }}
      />
    </Page>
  );
};

function useColumns(useFetchInvoiceQuery: UseFetchInvoiceQuery) {
  const t = useTranslate('shared-admin-ui.financial');

  return useMemo(() => {
    const columns = [
      columnHelper.accessor((originalRow) => originalRow?.sourceTransaction.invoice
        ? formatCurrency(originalRow?.sourceTransaction.invoice.amountDue, { currency: 'USD', cents: true })
        : null, {
        id: 'invoice.amount',
        header: t('invoiceAmount')
      }),
      columnHelper.accessor((originalRow) => t(`paymentTypes.${originalRow.type}`), {
        id: 'type',
        header: t('transactionType')
      }),
      columnHelper.accessor((originalRow) => originalRow.type === 'ANNUAL_FEE'
        ? 'Acadeum'
        : originalRow.sourceTransaction.institution?.name, {
        id: 'counterparty',
        header: t('counterparty'),
        cell: ({ row }) => {
          return row.original.type === 'ANNUAL_FEE'
            ? t('acadeum')
            : row.original.sourceTransaction.institution ? <DataBlock
              type="institution" institution={row.original.sourceTransaction.institution}
            /> : null;
        }
      }),
      columnHelper.accessor('sourceTransaction.invoice.createdAt', {
        header: 'Invoice Date',
        cell: ({ getValue }) => {
          return (
            <FormatDate
              utc={false}
              date={getValue()}
              month="short"
              day="numeric"
            />
          );
        }
      }),
      columnHelper.accessor('sourceTransaction.invoice.dueDate', {
        header: t('dueDate'),
        cell: ({ row }) => {
          const dueDate = row.original.sourceTransaction.invoice?.dueDate;
          if (!dueDate) {
            return null;
          }
          return (
            <FormatDate
              utc={false}
              date={dueDate}
              month="short"
              day="numeric"
            />
          );
        }
      }),
      columnHelper.accessor('sourceTransaction.invoice.status', {
        header: 'Invoice Status',
        cell: ({ getValue }) => <Tag variant={getValue()}/>
      }),
      columnHelper.accessor('sourceTransaction.invoice.number', {
        header: 'Invoice Number',
        cell: ({ row }) => <InvoiceNumberCell originalRow={row.original}/>
      }),
      columnHelper.accessor('sourceTransaction.invoice.id', {
        header: 'Stripe Invoice ID'
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ row, downloadRow }) => (
          <ActionsCell
            originalRow={row.original}
            downloadRow={downloadRow}
            useFetchInvoiceQuery={useFetchInvoiceQuery}
          />
        )
      })
    ];

    const exportDataColumns = columns.reduce<TableExportDataColumns<'xlsx', FetchTransfersOutputListItem>>((res,
      column) => {
      if (column.id === 'actions') {
        return res;
      }

      if (typeof column.header !== 'string') {
        throw new Error('Column header must be a string');
      }

      const widthDic = {
        amount: 7,
        createdAt: 25,
        dueDate: 25
      };

      res.push({
        title: column.header,
        width: widthDic[column['accessorKey']] || 18,
        value: row => {
          if (typeof column['accessorKey'] === 'string') {
            return get(row, column['accessorKey']);
          } else if (typeof column['accessorFn'] === 'function') {
            return column['accessorFn'](row);
          }
          throw new Error(`Invalid column accessor: ${column.header}`);
        }
      });

      return res;
    }, []);

    return { columns, exportDataColumns };
  }, [t]);
}

interface ActionsCellProps {
  originalRow: FetchTransfersOutputListItem;
  downloadRow: () => void;
  useFetchInvoiceQuery: UseFetchInvoiceQuery;
}

const ActionsCell: FC<ActionsCellProps> = ({
  downloadRow,
  originalRow,
  useFetchInvoiceQuery
}) => {
  const t = useTranslate('shared-admin-ui.financial');
  const [showInvoiceDetailsModal, setShowInvoiceDetailsModal] = useState(false);

  const { getPaymentHistoryUrl } = useSettingsRoutes();

  const actions = useMemo(() => [
    {
      title: t('download'),
      onClick: () => downloadRow()
    },
    {
      title: t('seeInvoiceDetails'),
      onClick: () => setShowInvoiceDetailsModal(true)
    },
    ...(originalRow.sourceTransaction.invoice?.status === 'paid' ? [
      {
        title: t('seePaymentDetails'),
        url: `${getPaymentHistoryUrl()}?search=${originalRow.sourceTransaction.id}`
      }
    ] : [])
  ], [t, originalRow, downloadRow]);

  return (
    <>
      <Actions
        variant="kebab"
        actions={actions}
      />

      {showInvoiceDetailsModal && originalRow.sourceTransaction.invoice && (
        <InvoiceDetails
          useFetchInvoiceQuery={useFetchInvoiceQuery}
          invoiceId={originalRow.sourceTransaction.invoice?.id}
          chargeId={originalRow.sourceTransaction.id}
          onHide={() => setShowInvoiceDetailsModal(false)}
        />
      )}
    </>
  );
};

interface InvoiceNumberCellProps {
  originalRow: FetchTransfersOutputListItem;
}

const InvoiceNumberCell: FC<InvoiceNumberCellProps> = ({ originalRow }) => {
  const [showUnableDownloadModal, setShowUnableDownloadModal] = useState(false);

  return (
    <>
      <Link
        external
        removeUnderline={false}
        to={originalRow.sourceTransaction.invoice?.invoicePdfUrl}
        onClick={(event) => {
          if (!originalRow.sourceTransaction.invoice?.invoicePdfUrl) {
            event.preventDefault();
            setShowUnableDownloadModal(true);
            return;
          }
        }}
      >
        {originalRow.sourceTransaction.invoice?.number}
      </Link>

      <UnableDownloadModal
        show={showUnableDownloadModal}
        onHide={() => setShowUnableDownloadModal(false)}
      />
    </>
  );
};

function useFetchDataForExport(useLazyFetchTransfersQuery: UseLazyFetchTransfersQuery) {
  const [lazyFetchTransfersQuery] = useLazyFetchTransfersQuery();

  return useCallback(async (ids) => {
    const pageSize = 1000;
    let page = 1;
    let allRecords: FetchTransfersOutputListItem[] = [];

    function fetchRecords() {
      const query: FetchTransfersInput = {
        page,
        pageSize,
        filters: {
          invoiceExists: true,
          ids: ids.length > 0 ? ids : undefined
        }
      };

      return lazyFetchTransfersQuery(query).unwrap().then((response) => {
        allRecords = allRecords.concat(response.results);
        page += 1;

        if (response.results.length === pageSize) {
          return fetchRecords();
        } else {
          return allRecords;
        }
      });
    }

    return await fetchRecords();
  }, [lazyFetchTransfersQuery]);
}
