import type { RtkApi } from '@acadeum/helpers';

export const injectFinancialSettingsEndpoints = (rtkApi: RtkApi) => rtkApi
  .enhanceEndpoints({ addTagTypes: ['financialSettingsStatus', 'primaryPaymentSource', 'backupPaymentSource'] })
  .injectEndpoints({
    endpoints: build => ({
      fetchFinancialSettingsStatus: build.query<FetchFinancialSettingsStatusOutput, void>({
        query: () => '/financial-settings-status',
        providesTags: ['financialSettingsStatus']
      }),
      fetchPrimaryPaymentSource: build.query<FetchPrimaryPaymentSourceOutput, void>({
        query: () => '/payment-sources/primary',
        providesTags: ['primaryPaymentSource']
      }),
      createStripeAccount: build.query<void, CreateStripeAccountInput>({
        query: params => ({
          url:'/payments/account',
          method: 'POST',
          params
        })
      }),
      createPrimaryPaymentSource: build.mutation<void, { sourceId: string }>({
        query: (body) => ({
          url: '/payment-sources/primary',
          method: 'POST',
          body
        }),
        invalidatesTags: ['primaryPaymentSource', 'financialSettingsStatus'],
        extraOptions: { retryCondition: () => false }
      }),
      updatePrimaryPaymentSource: build.mutation<void, { sourceId: string }>({
        query: (body) => ({
          url: '/payment-sources/primary',
          method: 'PUT',
          body
        }),
        invalidatesTags: ['primaryPaymentSource', 'financialSettingsStatus'],
        extraOptions: { retryCondition: () => false }
      }),
      verifyPrimaryPaymentSource: build.mutation<void, { amounts: [number, number], type: 'new' | 'current' }>({
        query: (body) => ({
          url: '/payment-sources/primary/verify',
          method: 'POST',
          body
        }),
        invalidatesTags: ['primaryPaymentSource', 'financialSettingsStatus'],
        extraOptions: { retryCondition: () => false }
      }),
      fetchBackupPaymentSource: build.query<FetchBackupPaymentSourceOutput, void>({
        query: () => '/payment-sources/backup',
        providesTags: ['backupPaymentSource']
      }),
      createBackupPaymentSource: build.mutation<void, { sourceId: string }>({
        query: (body) => ({
          url: '/payment-sources/backup',
          method: 'POST',
          body
        }),
        invalidatesTags: ['financialSettingsStatus', 'backupPaymentSource']
      }),
      updateBackupPaymentSource: build.mutation<void, { sourceId: string }>({
        query: (body) => ({
          url: '/payment-sources/backup',
          method: 'PUT',
          body
        }),
        invalidatesTags: ['financialSettingsStatus', 'backupPaymentSource'],
        extraOptions: { retryCondition: () => false }
      }),
      deleteBackupPaymentSource: build.mutation<void, void>({
        query: () => ({
          url: '/payment-sources/backup',
          method: 'DELETE'
        }),
        invalidatesTags: ['financialSettingsStatus', 'backupPaymentSource']
      })
    })
  });

export interface FetchFinancialSettingsStatusOutput {
  paymentMethod: 'ACH' | 'CARD';
  backupPaymentSourceStatus: string | null;
  primaryPaymentSourceStatus: string | null;
  financialOnboardingComplete: boolean;
}

export interface FetchPrimaryPaymentSourceOutput {
  current: PrimaryPaymentSource;
  new?: PrimaryPaymentSource;
}

export interface PrimaryPaymentSource {
  status: 'DEFAULT' | 'PENDING' | 'ACTIVE' | 'INVALID';
  bankAccount: {
    accountHolderName: string;
    accountHolderType: string;
    country: string;
    currency: string;
    lastFourDigits: string;
    routingNumber: string;
  };
}

export interface FetchBackupPaymentSourceOutput {
  status: 'DEFAULT' | 'INVALID' | 'EXPIRED' | 'ACTIVE';
  card: {
    brand: string;
    expiresMonth: number;
    expiresYear: number;
    lastFourDigits: string;
    name: string;
  };
}

interface CreateStripeAccountInput {
  /** Stripe account email */
  email: string;
}

type FinancialSettingsApi = ReturnType<typeof injectFinancialSettingsEndpoints>;

export type UseFetchFinancialSettingsStatusQuery = FinancialSettingsApi['useFetchFinancialSettingsStatusQuery'];
export type UseFetchPrimaryPaymentSourceQuery = FinancialSettingsApi['useFetchPrimaryPaymentSourceQuery'];
export type UseCreatePrimaryPaymentSourceMutation = FinancialSettingsApi['useCreatePrimaryPaymentSourceMutation']
export type UseUpdatePrimaryPaymentSourceMutation = FinancialSettingsApi['useUpdatePrimaryPaymentSourceMutation']
export type UseVerifyPrimaryPaymentSourceMutation = FinancialSettingsApi['useVerifyPrimaryPaymentSourceMutation']
export type UseFetchBackupPaymentSourceQuery = FinancialSettingsApi['useFetchBackupPaymentSourceQuery']
export type UseCreateBackupPaymentSourceMutation = FinancialSettingsApi['useCreateBackupPaymentSourceMutation']
export type UseUpdateBackupPaymentSourceMutation = FinancialSettingsApi['useUpdateBackupPaymentSourceMutation']
export type UseDeleteBackupPaymentSourceMutation = FinancialSettingsApi['useDeleteBackupPaymentSourceMutation']
