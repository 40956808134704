import type { RtkApi } from '@acadeum/helpers';
import type { Id, Student } from '@acadeum/types';

export type CreateStudentUserInput = Pick<Student, 'firstName'| 'lastName'| 'email'| 'hiStudentId'>
export interface CreateStudentUserOutput extends Pick<Student, 'firstName'| 'lastName'| 'email'| 'hiStudentId' | 'id'> {
  knownStudent: {
    id: Id;
  }
}

export interface FetchStudentUserInput {
  id: Id;
}

export type FetchStudentUserOutput = Pick<Student, 'firstName' | 'lastName'| 'email'| 'hiStudentId' | 'id'>

export interface UpdateStudentUserInput {
  id: Id,
  body: Pick<Student, 'firstName' | 'lastName' | 'hiStudentId'>
} 

export type UpdateStudentUserOutput = Pick<Student, 'firstName' | 'lastName'| 'email'| 'hiStudentId' | 'id'>

export interface ActivateStudentUserInput {
  id: Id;
}

export interface DeactivateStudentUserInput {
  id: Id;
}

export interface DeleteStudentUserInput {
  id: Id;
}

export const injectStudentUsersEndpoints = (rtkApi: RtkApi) => rtkApi
  .enhanceEndpoints({addTagTypes: ['studentUsers']})
  .injectEndpoints({
    endpoints: build =>({
      fetchStudentUser: build.query<FetchStudentUserOutput, FetchStudentUserInput>({
        query: ({ id }) => ({
          url: `/student-users/${id}`
        }),
        providesTags: (result, error, arg) => [{ type: 'studentUsers', id: arg.id }]
      }),
      createStudentUser: build.mutation<CreateStudentUserOutput, CreateStudentUserInput>({
        query: body => ({
          url: '/student-users',
          method: 'POST',
          body
        }),
        invalidatesTags: ['studentUsers']
      }),
      updateStudentUser: build.mutation<UpdateStudentUserOutput, UpdateStudentUserInput>({
        query: ({ id, body }) => ({
          url: `/student-users/${id}`,
          method: 'PUT',
          body
        }),
        invalidatesTags: ['studentUsers']
      }),
      activateStudentUser: build.mutation<void, ActivateStudentUserInput>({
        query:({ id }) => ({
          url: `/student-users/${id}/activate`,
          method: 'POST'
        }),
        invalidatesTags: ['studentUsers']
      }),
      deactivateStudentUser: build.mutation<void, DeactivateStudentUserInput>({
        query:({ id }) => ({
          url: `/student-users/${id}/deactivate`,
          method: 'POST'
        }),
        invalidatesTags: ['studentUsers']
      }),
      deleteStudentUser: build.mutation<void, DeleteStudentUserInput>({
        query:({ id }) => ({
          url: `/student-users/${id}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['studentUsers']
      })
    })
  });
